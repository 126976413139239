define("discourse/plugins/discourse-restricted-replies/discourse/templates/components/restrict-replies-setting", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="field restrict-replies">
    <h3>{{i18n "restricted_replies.title"}}</h3>
    <label>
      <Input
        @type="checkbox"
        @checked={{this.category.custom_fields.restrict_replies}}
      />
      {{i18n "restricted_replies.for_category"}}
    </label>
    {{#if this.category.custom_fields.restrict_replies}}
      <MultiSelect
        @content={{this.groups}}
        @allowAny={{false}}
        @value={{this.category.custom_fields.restrict_replies_bypass_groups}}
        @onSelect={{action
          (mut this.category.custom_fields.restrict_replies_bypass_groups)
        }}
      />
    {{/if}}
  </section>
  */
  {
    "id": "hFNgsExr",
    "block": "[[[10,\"section\"],[14,0,\"field restrict-replies\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,[28,[35,0],[\"restricted_replies.title\"],null]],[13],[1,\"\\n  \"],[10,\"label\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"category\",\"custom_fields\",\"restrict_replies\"]]]],null],[1,\"\\n    \"],[1,[28,[35,0],[\"restricted_replies.for_category\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"category\",\"custom_fields\",\"restrict_replies\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@content\",\"@allowAny\",\"@value\",\"@onSelect\"],[[30,0,[\"groups\"]],false,[30,0,[\"category\",\"custom_fields\",\"restrict_replies_bypass_groups\"]],[28,[37,4],[[30,0],[28,[37,5],[[30,0,[\"category\",\"custom_fields\",\"restrict_replies_bypass_groups\"]]],null]],null]]],null],[1,\"\\n\"]],[]],null],[13]],[],false,[\"i18n\",\"input\",\"if\",\"multi-select\",\"action\",\"mut\"]]",
    "moduleName": "discourse/plugins/discourse-restricted-replies/discourse/templates/components/restrict-replies-setting.hbs",
    "isStrictMode": false
  });
});